<template>
  <div>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText || "Información actualizada correctamente." }}
    </v-snackbar>

    <v-overlay class="loading-center" :value="save">
      <lottie
        :options="defaultOptions"
        :height="300"
        :width="400"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <div>
      <v-container fluid class="fill-height">
        <v-row class="pa-0 ma-0">
          <v-col cols="12" sm="7" md="7">
            <h1>
              Categorías de
              <span class="font-weight-light">
                {{ getBusinessName }}
              </span>
            </h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-row justify="end">
              <v-btn
                color="primary"
                class="ma-2 white--text mr-5"
                @click="modalSort = true"
                :loading="loading"
              >
                <v-icon right dark class="mr-3">fas fa-bars</v-icon>
                Ordenar
              </v-btn>

              <v-btn
                color="primary"
                class="ma-2 white--text"
                @click="openModalAdd_Update(false)"
                :loading="loading"
              >
                <v-icon right dark class="mr-3">fas fa-plus</v-icon>
                Crear nueva categoría
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="getMenu"
              :search="$store.state.search"
              :items-per-page="15"
              fixed-header
              :loading="loading"
              :expanded.sync="expanded"
              :sort-by="['position']"
              :sort-desc="[false, true]"
              show-expand
              :single-expand="true"
              item-key=".key"
              height="75vh"
              :footer-props="{
                itemsPerPageOptions: [10, 25, 50, 100, 250],
              }"
            >
              <template v-slot:[`item.active`]="{ item }">
                <div style="display: flex ; justify-content: center;!important">
                  <v-switch
                    @change="activeUpdate(item)"
                    v-model="item.active"
                  ></v-switch>
                </div>
              </template>

              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="pa-4">
                  <section-details
                    :key="item['.key']"
                    :business="business"
                    :section="item"
                  ></section-details>
                </td>
              </template>

              <template v-slot:[`item.options`]="{ item }">
                <v-btn
                  @click="add_update_calendar(item)"
                  class="mr-2"
                  small
                  color="primary"
                >
                  Horarios
                </v-btn>
                <v-btn
                  @click="openModalAdd_Update(true, item)"
                  small
                  color="#FCC341"
                  style="color: #fff"
                >
                  Editar
                </v-btn>
                <v-btn
                  class="ma-2 white--text"
                  @click="
                    itemToDelete = item;
                    modalDelete = true;
                  "
                  small
                  color="red darken-4"
                >
                  Eliminar
                </v-btn>
              </template>

              <template v-slot:loading>
                <div class="ma-2" style="position: relative">
                  <div
                    style="
                      position: absolute;
                      z-index: 999;
                      width: 100%;
                      height: 100%;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <lottie
                      :options="defaultOptions"
                      :height="300"
                      :width="400"
                      v-on:animCreated="handleAnimation"
                    />
                    <p
                      :class="
                        $vuetify.theme.dark
                          ? 'subtitle-1 primary--text'
                          : 'subtitle-1 black--text'
                      "
                    >
                      Cargando Data
                    </p>
                  </div>
                  <v-skeleton-loader
                    ref="skeleton"
                    type="table-tbody"
                    class="mx-auto d-none d-md-block"
                  ></v-skeleton-loader>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- Add && update sections -->
    <v-dialog persistent v-model="modalAddandUpdateSection" max-width="500px">
      <v-card>
        <v-card-title class="grid-close">
          <span class="headline">
            {{
              isEdit
                ? "Editar categoría de productos"
                : "Nueva categoría de productos"
            }}
          </span>
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            absolute
            @click="handleCreateCancel"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row class="mb-6">
              <v-col cols="12">
                <p>Nombre de la categoría</p>
                <input
                  class="control-input"
                  type="text"
                  placeholder="Ingrese el nombre de la categoría"
                  v-model="newItem.name"
                />
              </v-col>
            </v-row>

            <v-divider></v-divider>
            <v-row class="pa-0 ma-0">
              <v-col cols="6">
                <v-row justify="start">
                  <v-switch
                    label="Visible en el app"
                    v-model="newItem.active"
                  ></v-switch>
                </v-row>
              </v-col>

              <v-col cols="6">
                <v-row justify="end">
                  <v-btn
                    class="save-btn"
                    color="primary"
                    v-if="isEdit"
                    @click="updateSections"
                  >
                    Editar
                  </v-btn>
                  <v-btn
                    class="save-btn"
                    color="primary"
                    v-else
                    @click="newSection"
                  >
                    Guardar
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Add y update calendario -->
    <v-dialog
      persistent
      v-if="modalEditCalendar"
      v-model="modalEditCalendar"
      max-width="80%"
    >
      <v-card>
        <v-card-title class="grid-close">
          <span class="headline">Administrar horarios</span>
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            absolute
            @click="modalEditCalendar = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <schedule-c :loading="loading" :schedule="newItem.schedule" />
              </v-col>

              <v-col cols="12" class="note mb-4">
                <small>
                  *Llenar el horario no es obligatorio, si las secciones no
                  cuentan con un horario propio adoptarán el horario del
                  comercio.
                </small>
              </v-col>
            </v-row>

            <v-divider></v-divider>
            <v-row class="pa-0 ma-0">
              <v-spacer></v-spacer>
              <v-col cols="6">
                <v-row justify="end">
                  <v-btn
                    class="save-btn"
                    color="primary"
                    @click="UpdateSchedule"
                  >
                    Guardar
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Edit secctions -->
    <!-- <v-dialog v-model="modalEditSection" max-width="1000px">
      <section-edit
        :schedule="scheduleEdited"
        @success="
          modalEditSection = false;
          snackbar = true;
        "
        @cancel="modalEditSection = false"
        :key="sectionSelected['.key']"
        :business="business"
        :sections="menu"
        :section="sectionSelected"
      />
    </v-dialog> -->

    <v-dialog scrollable persistent v-model="modalSort" max-width="500px">
      <sort
        :key="Math.random()"
        @success="handleSort"
        :categories="menu"
        @cancel="modalSort = false"
      ></sort>
    </v-dialog>

    <!-- modal delete  -->
    <v-dialog max-width="400" v-if="itemToDelete" v-model="modalDelete">
      <v-card>
        <v-card-title class="headline">
          Confirmar acción
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            absolute
            @click="modalDelete = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="mt-3">
          ¿Está seguro que desea eliminar
          <span class="font-weight-bold">
            {{ itemToDelete.name }}
          </span>
          ?
        </v-card-text>

        <v-card-actions style="justify-content: center">
          <v-btn
            color="red darken-4"
            width="90%"
            style="border-radius: 50px"
            class="mb-3"
            dark
            @click="confirmDelete"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import { mapState } from "vuex";
import sectionDetails from "./section-details";
import editSection from "./edit-section";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { Button } from "ant-design-vue";
import { TimePicker } from "ant-design-vue";
import sort from "./sort";
import ledgerMixin from "@/mixins/ledgerMixin";
import scheduleC from "@/components/own-schedule";
export default {
  name: "Section",
  mixins: [lottieSettings, ledgerMixin],
  components: {
    lottie: Lottie,
    scheduleC,
    "section-details": sectionDetails,
    "section-edit": editSection,
    "a-time-picker": TimePicker,
    "a-button": Button,
    sort,
  },
  data() {
    return {
      business: null,
      modalEditCalendar: false,
      item: 0,
      rndKey: 0,
      menu: [],
      isEdit: false,
      itemToDelete: null,
      time: null,
      menu2: false,
      waiting: true,
      daySelected: "monday",
      modalDelete: false,
      modalAddandUpdateSection: false,
      modalSort: false,
      headers: [
        { text: "Posición", value: "position" },
        { text: "Nombre", value: "name" },
        {
          text: "Visible en el app",
          value: "active",
          align: "center",
          justify: "center",
        },
        { text: "", value: "options", align: "end" },
      ],
      loading: true,
      expanded: [],
      iterationArray: [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday",
      ],
      days: {
        monday: { text: "Lunes" },
        tuesday: { text: "Martes" },
        wednesday: { text: "Miércoles" },
        thursday: { text: "Jueves" },
        friday: { text: "Viernes" },
        saturday: { text: "Sábado" },
        sunday: { text: "Domingo" },
      },
      newItem: {
        active: true,
        name: "",
        position: "",
        isAvailable: true,
        deleted: false,
        createdAt: fb.firestore.Timestamp.fromDate(this.getTime()),
        createdBy: this.$store.state.user[".key"],
        schedule: {
          activeMonday: false,
          activeTuesday: false,
          activeWednesday: false,
          activeThursday: false,
          activeFriday: false,
          activeSaturday: false,
          activeSunday: false,
          monday: [],
          tuesday: [],
          wednesday: [],
          thursday: [],
          friday: [],
          saturday: [],
          sunday: [],
        },
      },
      days: [
        { text: "Lunes", value: "monday", active: "activeMonday" },
        { text: "Martes", value: "tuesday", active: "activeTuesday" },
        { text: "Miércoles", value: "wednesday", active: "activeWednesday" },
        { text: "Jueves", value: "thursday", active: "activeThursday" },
        { text: "Viernes", value: "friday", active: "activeFriday" },
        { text: "Sábado", value: "saturday", active: "activeSaturday" },
        { text: "Domingo", value: "sunday", active: "activeSunday" },
      ],
      dayisActive: {
        activeMonday: false,
        activeTuesday: false,
        activeWednesday: false,
        activeThursday: false,
        activeFriday: false,
        activeSaturday: false,
        activeSunday: false,
      },
      setupSchedule: false,
      rules: [(value) => !!value || "Required."],
      tagsToSearch: [],
      search: "",
      searchableTags: [{ name: "Activo", color: "lime darken-1" }],
      sectionSelected: {},
      modalEditSection: false,
      // modalEditSchedule : false,
      scheduleEdited: {},
      snackbarText: "",
      snackbar: "",
      save: false,
      sectionsReference: null,
    };
  },
  watch: {
    selectedBusiness() {
      this.menu = [];
      this.getBusinessData();
    },
  },
  computed: {
    ...mapState(["selectedBusiness"]),
    getBusinessName() {
      return this.$store.state.selectedBusiness.shortName;
    },
    getMenu() {
      return this.menu.filter((item) => !item.deleted || item.deleted == false);
    },
  },
  methods: {
    moment,
    openModalAdd_Update: function (val, item) {
      if (val) {
        this.isEdit = true;
        this.editSectionFn(item);
        this.modalAddandUpdateSection = true;
        this.newItem = Object.assign({}, item);
      } else {
        this.isEdit = false;
        this.rndKey = Math.random();
        this.modalAddandUpdateSection = true;
      }
    },
    add_update_calendar(item) {
      var temp = Object.assign({}, item);

      this.newItem[".key"] = temp[".key"];
      if (temp.schedule.saturday) {
        console.log("si  tiene");
        this.newItem.schedule = temp.schedule;
        this.modalEditCalendar = true;
      } else {
        console.log("no  tiene");
        this.newItem.schedule = {
          saturday: [],
          activeSunday: false,
          activeTuesday: false,
          friday: [],
          tuesday: [],
          thursday: [],
          activeWednesday: false,
          activeSaturday: false,
          activeMonday: false,
          wednesday: [],
          activeFriday: false,
          sunday: [],
          activeThursday: false,
          monday: [],
        };
        this.modalEditCalendar = true;
      }
    },
    updateSections() {
      db.collection(`businesses/${this.selectedBusiness[".key"]}/qrMenu`)
        .doc(this.newItem[".key"])
        .update({
          name: this.newItem.name,
          active: this.newItem.active,
        })
        .then(() => {
          this.snackbar = true;
          this.snackbarText = "Categoria actualizada con exito";
          this.modalAddandUpdateSection = false;
          this.addedSuccess();
        })
        .catch(() => {
          this.save = false;
          this.snackbar = true;
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
        });
    },
    UpdateSchedule() {
      db.collection(`businesses/${this.selectedBusiness[".key"]}/qrMenu`)
        .doc(this.newItem[".key"])
        .update({
          schedule: this.newItem.schedule,
        })
        .then(() => {
          this.snackbar = true;
          this.snackbarText = "Categoria actualizada con exito";
          this.modalEditCalendar = false;
          this.addedSuccess();
        })
        .catch(() => {
          this.save = false;
          this.snackbar = true;
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
        });
    },
    handleSort() {
      this.snackbarText =
        "Posiciones de las categorías actualizadas correctamente.";
      this.snackbar = true;
      // this.modalSort = false
    },
    showSchedule: function (day) {
      this.waiting = false;
      this.daySelected = day;

      setTimeout(() => {
        this.waiting = true;
      }, 100);
    },
    handleClose(order, index) {
      if (order == 1)
        this.newItem.schedule[this.daySelected][index].activeOpens = false;
      else this.newItem.schedule[this.daySelected][index].activeCloses = false;
    },
    changeOpens(time, index) {
      this.newItem.schedule[this.daySelected][index].opensAt =
        moment(time).format("HH:mm:ss");
    },
    changeCloses(time, index) {
      this.newItem.schedule[this.daySelected][index].closesAt =
        moment(time).format("HH:mm:ss");
    },
    activeUpdate: function (item) {
      this.save = true;

      let docRef = db
        .collection(`businesses/${this.selectedBusiness[".key"]}/qrMenu`)
        .doc(item[".key"]);

      docRef
        .update({
          active: item.active,
        })
        .then(() => {
          this.snackbarText = `Sección de ${item.name} ${
            item.active ? "activa" : "inactiva"
          }`;
          this.snackbar = true;
          this.save = false;

          this.createLedger({
            source_id: docRef.id,
            source_type: "menu",
            currentValue: { active: item.active },
            prevValue: { active: !item.active },
          });
        })
        .catch(() => {
          item.active = !item.active;
          this.snackbarText = `Ocurrió un error, inténtelo nuevamente.`;
          this.snackbar = true;
          this.save = false;
        });
    },
    getTime() {
      let arr = moment()
        .tz("America/Tegucigalpa")
        .format("YYYY, MM, DD, HH, mm, ss, 0")
        .split(",");
      return new Date(
        parseInt(arr[0]),
        parseInt(arr[1]) - 1,
        parseInt(arr[2]),
        parseInt(arr[3]),
        parseInt(arr[4]),
        parseInt(arr[5]),
        parseInt(arr[6])
      );
    },
    editSectionFn: function (item) {
      this.scheduleEdited = {};
      for (let i = 0; i < this.iterationArray.length; i++) {
        const day = this.iterationArray[i];
        this.scheduleEdited[day] = item.schedule.hasOwnProperty(day)
          ? item.schedule[day]
          : [];
      }
      this.sectionSelected = item;
      this.modalEditSection = true;
    },

    handleCreateCancel() {
      this.newItem.name = "";
      this.newItem.position = "";
      this.newItem.schedule = {
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
        sunday: [],
      };
      this.modalAddandUpdateSection = false;
    },
    addedSuccess: function () {
      this.modalAddandUpdateSection = false;
      this.save = false;
      this.newItem.name = "";
      this.newItem.position = "";
      this.newItem.schedule = {
        activeMonday: false,
        activeTuesday: false,
        activeWednesday: false,
        activeThursday: false,
        activeFriday: false,
        activeSaturday: false,
        activeSunday: false,
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
        sunday: [],
      };
    },
    addSchedule: function () {
      this.newItem.schedule[this.daySelected].push({
        opensAt: "00:00:00",
        closesAt: "00:00:00",
      });
    },
    removeSchedule: function (position) {
      this.newItem.schedule[this.daySelected].splice(position, 1);
    },

    newSection: function () {
      this.newItem.createdAt = fb.firestore.Timestamp.fromDate(this.getTime());
      this.newItem.position = this.getMenu.length + 1;
      if (this.newItem.name) {
        this.save = true;

        db.collection(`businesses/${this.selectedBusiness[".key"]}/qrMenu`)
          .add(this.newItem)
          .then((ref) => {
            this.addedSuccess(ref.id);

            this.createLedger({
              source_id: ref.id,
              source_type: "menu",
              currentValue: this.newItem,
              prevValue: {},
            });
          })
          .catch(() => {
            this.snackbar = true;
            this.snackbarText = "Ocurrió un error, inténtelo nuevamente.";
            this.save = false;
          });
      } else {
        this.snackbar = true;
        this.snackbarText = "Ingrese un nombre y una posición válida";
      }
    },
    csvExport(arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";

      let excelHeader = [
        {
          "Nombre de la categoria": "",
          Posicion: "",
          Activo: "",
        },
      ];

      csvContent += [
        Object.keys(excelHeader[0]).join(";"),
        ...arrData.map(
          (item) =>
            `${item.name};${item.position};${item.active ? "Si" : "No"};`
        ),
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute(
        "download",
        `${this.business.shortName}_categorias(${moment()
          .tz("America/Tegucigalpa")
          .format("DD-MM-YYYY")}).csv`
      );
      link.click();
    },
    confirmDelete: function () {
      if (this.itemToDelete) {
        db.collection(`businesses/${this.selectedBusiness[".key"]}/qrMenu`)
          .doc(this.itemToDelete[".key"])
          .update({
            deleted: true,
            active: false,
            deletedAt: fb.firestore.Timestamp.fromDate(this.getTime()),
            deletedBy: this.$store.state.user[".key"],
          })
          .then(() => {
            this.modalDelete = false;
            this.snackbar = true;
            this.snackbarText = "Elemento borrado";

            this.createLedger({
              source_id: this.itemToDelete[".key"],
              source_type: "menu",
              currentValue: {
                deleted: true,
                active: false,
                deletedAt: fb.firestore.Timestamp.fromDate(this.getTime()),
                deletedBy: this.$store.state.user[".key"],
              },
              prevValue: this.itemToDelete,
            });
          })
          .catch(() => {
            this.modalDelete = false;
            this.snackbar = true;
            this.snackbarText = "Occrrió un error, inténtelo nuevamente.";
          });
      }
    },
    async getBusinessData() {
      if (!this.selectedBusiness) this.$router.push({ path: "/" });
      this.loading = true;
      this.tagsToSearch = "";
      this.$store.state.search = "";

      let business = this.$binding(
        "business",
        db.collection("businesses").doc(this.selectedBusiness[".key"])
      );

      let menu = this.$binding(
        "menu",
        db.collection(`businesses/${this.selectedBusiness[".key"]}/qrMenu`)
      ).then(() => {
        // this.menu = this.menu.filter( item => item.deleted == undefined || item.deleted == false)
      });

      await Promise.all([business, menu]);
      this.loading = false;
    },
  },

  mounted() {
    document
      .getElementsByClassName("v-data-table__wrapper")[0]
      .addEventListener("scroll", () => {
        document.body.scrollTop = document.documentElement.scrollTop =
          document.getElementsByClassName("v-data-table__wrapper")[0].scrollTop;
      });

    this.$store.state.visibleSearch = true;
    this.$store.state.searchTerm = "sección de productos";

    this.getBusinessData();
  },

  destroyed() {
    if (this.sectionsReference) this.sectionsReference();

    this.$store.commit("setSearchTerm", "");
    this.$store.commit("setSearch", "");
  },
};
</script>

<style scoped>
.table-sections {
  margin: 5%;
}
.add-schedule {
  display: flex;
  justify-content: center;
}
.close-modal {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.grid-close {
  display: grid;
  grid-template-columns: auto auto;
}
.note {
  border: 1px solid #00cdbc;
  border-radius: 13px;
  padding: 10px;
  background-color: #8080801f;
}
.theme--light.v-list {
  background: white !important;
}
p {
  padding: 0;
  margin: 0;
  color: rgba(0, 0, 0, 0.836);
  font-size: 16px;
}
.add-schedule {
  display: flex;
  justify-content: flex-end;
}
.fa-times {
  color: gray;
}
.delete-schedule-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.delete-schedule {
  margin: 0;
  cursor: pointer;
  padding-right: 5px;
  color: #f06f3d;
}
.save-btn {
  margin-top: 20px;
}
.actions {
  position: fixed;
  bottom: 0;
  width: 80%;
  bottom: 20px;
  padding-right: 20px;
  background-color: white;
}
.fixed-position {
  z-index: 100;
}
</style>
